import cx from 'classnames';

import { Container } from '@hubcms/ui-container';
import { TeaserGrid, TeaserGridExtensionsProvider } from '@hubcms/ui-teaser-grid';
import { createGAdExtension, TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { isThemed, getThemeDataAttributes } from '@hubcms/utils-theme';
import { removeStoryblockTopPaddingForTheme } from '@hubcms/brand';

import styles from './storyblock.module.scss';
import { StoryblockProps } from './types';

function Storyblock({
  className,
  theme = 'none',
  hasNoPaddingBlock = false,
  hasNoPaddingInline = false,
  hasTopMargin = false,
  gridData,
  groupId,
  groupType,
  gridExtensions,
  adElement,
}: StoryblockProps) {
  const extensions: TeaserGridExtensions = {
    ...gridExtensions,
    ...createGAdExtension(adElement),
  };

  const themeDataAttributes = getThemeDataAttributes(theme);

  if (!gridData.items || !gridData.items.length) {
    return null;
  }

  // spreading key prop leads to console errors and we don't actually need a key here
  const { key, ...gridDataWithoutKey } = gridData;

  return (
    <Container
      id={groupId}
      as="section"
      className={cx(
        styles.storyblock,
        {
          [styles.noPaddingBlock]: hasNoPaddingBlock,
          [styles.noTopPaddingBlock]: removeStoryblockTopPaddingForTheme && isThemed(theme),
          [styles.withTopMargin]: hasTopMargin,
        },
        className,
        'no-print',
      )}
      fullWidthSm
      fullWidthMd
      fullWidthLg={hasNoPaddingInline}
      {...themeDataAttributes}
      data-testid={`storyblock-type-${groupType}`}
    >
      <TeaserGridExtensionsProvider initialExtensions={extensions}>
        <TeaserGrid {...gridDataWithoutKey} isRoot />
      </TeaserGridExtensionsProvider>
    </Container>
  );
}

export default Storyblock;
